<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
        spinner="spinner" />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 lg4 pl-6 pt-7>
            <span style="font-size: 25px; font-weight: bolder">NATURE CAMPS</span>
          </v-flex>
          <v-flex xs11 lg8 pt-5>
            <v-layout wrap justify-end>
              <!-- <v-flex xs4 pt-3>
                              <v-form @submit.prevent="validateInput">
                                  <v-autocomplete dense outlined style="border-radius: 0%;background-color: red;"
                                      label="Circle" color="black" :items="circle" v-model="circleid"
                                      item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                                      class="custom-autocomplete  mainfont">
                                      <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                      </template>
                                  </v-autocomplete>
                              </v-form>
                          </v-flex> -->

              <!-- <v-flex xs4 pt-3 pl-4>
                              <v-form @submit.prevent="validateInput">
                                  <v-autocomplete dense style="border-radius: 0%;" outlined label="Division" color="black"
                                      :items="division" v-model="divisionid" item-text="officeName" item-value="_id"
                                      item-color="#FF1313" hide-details class="custom-autocomplete  mainfont">
                                      <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                      </template>
                                  </v-autocomplete>
                              </v-form>
                          </v-flex> -->

              <v-flex xs12 lg3 pt-3 pr-4 pl-4>
                <v-form @submit.prevent="validateInput">
                  <v-autocomplete dense style="border-radius: 0%" outlined label="Range" color="black" :items="range"
                    v-model="rangeid" item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                    class="custom-autocomplete mainfont">
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-form>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout v-if="list.length > 0" pl-3 pr-4 style="height: 100vh" wrap fill-height>
          <v-flex pl-3 v-for="(item, i) in list" :key="i" text-center xs11 lg3 class="mainbg2" pt-3>
            <v-card class="elevation-3">
              <v-card-text class="pa-0">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                  </v-flex>
                  <v-flex xs11 pt-3 text-center style="font-size: 23px; color: #000000; font-weight: bold">
                    {{ item.campName }}
                  </v-flex>
                  <v-flex xs11 text-right pt-2>

                    <!-- <v-btn block elevation="0" color="primary" @click="(reserveDialog = true), (curid = item._id)">
                      <span style="color: white">Block Date</span>
                    </v-btn> -->
                    <v-btn block elevation="0" color="primary" @click="$router.push('/blockdate?id=' + item._id)">
                      <span style="color: white">Block Date</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs11 text-right pt-2>
                    <v-btn block elevation="0" color="purple" @click="$router.push('/ViewReservations?id=' + item._id)">
                      <span style="color: white">View Blocked Dates</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs11 text-right pt-2>
                    <v-btn block elevation="0" color="#283e51" @click="$router.push('/editib?id=' + item._id)">
                      <span style="color: white">Edit</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs11 text-right pt-2 pb-4>
                    <v-btn block elevation="0" color="red" @click="(deletedialog = true), (curid = item._id)">
                      <span style="color: white">Delete</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
    <v-dialog v-model="reserveDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title class="headline">Reserve Date</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <!-- From Date Picker -->
              <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable outlined readonly hide-details="auto" v-model="formattedStartDate"
                    v-bind="attrs" background-color="white" v-on="on" @click="menuFrom = !menuFrom" style="color: black">
                    <template v-slot:append>
                      <v-icon color="black">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Block Date From:</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="startDate" color="#13736f" @input="updateFormattedStartDate"
                  :min="minDate"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 pt-10>
              <!-- To Date Picker -->
              <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable outlined readonly hide-details="auto" v-model="formattedEndDate" v-bind="attrs"
                    background-color="white" v-on="on" @click="menuTo = !menuTo" style="color: black">
                    <template v-slot:append>
                      <v-icon color="black">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Block Date To:</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="endDate" color="#13736f" @input="updateFormattedEndDate"
                  :min="startDate"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="reserveDates">OK</v-btn>
          <v-btn @click="reserveDialog = false; clearDates()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewreserveDialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>View Reservation</v-card-title>
      <v-card-text>
        <!-- Display the list of data here -->
        <v-list>
          <v-list-item v-for="(item, index) in reservationList" :key="index">
            <!-- Display data properties as needed -->
            <v-list-item-content>
              <v-list-item-title>{{ item.propertyName }}</v-list-item-title>
              <!-- Add more v-list-item-content as needed -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      viewreserveDialog: false,
      reservationList: [],
      name: null,
      barlist: [],
      userProfileTabs: null,
      subtab: null,
      circleid: "",
      divisionid: "",
      division: [],
      circle: [],
      startDate: null,
      endDate: null,
      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,
      minDate: new Date().toISOString().substr(0, 10),
      minToDate: null,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      editdailog: false,
      range: [],
      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      limit: 10,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      noOfRooms: null,
      path: null,
      accName: null,
      rangeid: null,
      maxGuestPerRoom: null,
      reserveDialog: false,
      msg: null,
      currentPage: 1,

      showSnackBar: false,
      curid: [],
      dialog2: false,
    };
  },
  created() {
    this.path = localStorage.getItem("path");
  },
  watch: {
    fromDate: {
      handler(newDate) {

        this.minToDate = newDate;
      },
      immediate: true,
    },
    rangeid() {
      this.getList();
    },
    circleid() {
      this.getDivision();
      this.getList();
    },
    divisionid() {
      this.getRange();
      this.getList();
    },
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
    this.getRange();
  },
  methods: {
    openDialog(curid) {
      console.log("daddwqq",this.curid)
      // Fetch data from the backend when dialog is opened
      this.fetchReservationData(curid);
      this.viewreserveDialog = true;
    },
    closeDialog() {
      this.viewreserveDialog = false;
    },
    fetchReservationData(curid) {
      axios({
        method: 'POST',
        url: '/list/block',
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: curid,
        },
      })
        .then((response) => {
          this.reservationList = response.data;
           // Assuming the response is an array of data
        })
        .catch((error) => {
          console.error('Error fetching reservation data:', error);
        });
    },
    reserveDates() {
      if (this.startDate && this.endDate) {
        axios({
          method: 'POST',
          url: '/divsion/blockdates',
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            campid: this.curid,
            fromDate: this.startDate,
            toDate: this.endDate,
          },
        })
          .then((response) => {
            console.log(response.data);
            this.startDate = null;
            this.endDate = null;
            this.formattedStartDate = null;
            this.formattedEndDate = null;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.reserveDialog = false;
          })
          .catch((response) => {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          });
      } else {
        console.error('Please select both start date and end date.');
      }
    },
    updateFormattedStartDate() {
      this.formattedStartDate = this.startDate ? this.formatDate(this.startDate) : null;
    },

    updateFormattedEndDate() {
      this.formattedEndDate = this.endDate ? this.formatDate(this.endDate) : null;
    },

    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('en-GB', options);
    },
    deleteItem() {
      axios({
        url: "/delete/naturecamp",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: this.curid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {},
      })
        .then((response) => {
          this.circle = response.data.data;

          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getDivision() {
      this.appLoading = true;
      const selectedCircle = this.circle.find((c) => c._id === this.circleid);
      if (selectedCircle) {
        const path = selectedCircle.path;
        axios({
          method: "post",
          url: "/get/divsions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.division = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getRange() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/get/range/v1",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          rangeid: this.rangeid,
          // path: this.path,
        },
      })
        .then((response) => {
          this.range = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    edit() {
      axios({
        method: "POST",
        url: "/edit/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          noOfRooms: this.curid.noOfRooms,
          accName: this.curid.accName,
          maxGuestPerRoom: this.curid.maxGuestPerRoom,
          accid: this.curid._id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    addIb() {
      axios({
        method: "POST",
        url: "/add/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          noOfRooms: this.noOfRooms,
          accName: this.accName,
          maxGuestPerRoom: this.maxGuestPerRoom,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    bookNow() {
      alert("Booking Now!");
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/all/naturecamp",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          rangeid: this.rangeid,
          circleid: this.circleid,
          divisionid: this.divisionid,

          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style >
.cus3 {
  height: 50px;
  background-color: white;
}

.vertical-divider {
  border-right: 1px solid black;
  height: 70px;
}

.v-date-picker .v-picker__body {
  box-shadow: none;
}

#chart-container {
  background: linear-gradient(180deg, #29807c 0%, rgba(5, 112, 107, 0.16) 100%);
}

/* Add some styling to the cards */
.elevation-3 {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s box-shadow;
}

.elevation-3:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.headline {
  font-size: 18px;
  font-weight: bold;
}

.glitter-button {
  position: relative;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
}

.glitter-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
  background-size: 50px 50px;
  animation: glitter 1s linear infinite;
  opacity: 0;
}

@keyframes glitter {
  0% {
    transform: translate(-25%, -25%);
  }

  100% {
    transform: translate(25%, 25%);
    opacity: 1;
  }
}
</style>